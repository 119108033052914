<template>
  <div class="cn-form-select cn-form-select-border">
    <Multiselect
      :disabled="!options.length"
      :can-deselect="false"
      :can-clear="false"
      :value="modelValue"
      :options="options"
      @change="onChange"
      @select="$emit('select', $event)"
      @open="isOpen = true"
      @close="isOpen = false">

      <template #caret>
        <span
          class="multiselect-caret caret"
          :class="{ caretOpen: isOpen }"
          :style="cssProps"
          aria-hidden="true"
        />
      </template>

    </Multiselect>
    <label v-show="label"
      :class="['cn-form-select-label', modelValue ? 'cn-form-select-label-selected' : '']"
      @click="$refs.select.open()">
      {{ label }}
    </label>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
export default{
  name: "OptionsDropdown",
  components: { Multiselect },
  props: ['modelValue', 'label', 'options'],
  data() {
    return {
      val: null,
      isOpen: false,
      cssProps: {
        '-webkit-mask-image': `url(${require('@/assets/Joel/caret.svg')})`,
        'background-color': '#0895D1',
        margin: 0,
      }
    }
  },
  methods: {
    onChange(value) {
      this.val = value
      this.$emit('update:modelValue', value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
