<template tag="span">
  <node-view-wrapper  class="dom">
    <node-view-content class="content-dom" />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps, NodeViewContent } from '@tiptap/vue-3'

export default {
  components: {
    NodeViewContent,
    NodeViewWrapper,
  },

  props: nodeViewProps,
  data: (vm) => ({
    initToContent: vm.node.attrs.to,
    initFromContent: vm.node.attrs.from,
  }),
  computed: {
    wrapperClasses() {
      return {
      }
    },
    actorType() {
      return this.node.attrs.actor
    },
  },
  methods: {
    isMode(mode) {
      return this.editor.storage.CCUtils.mode === mode
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  &-from {
    color: red;
    text-decoration: line-through;
  }
  &-to {
    color: green;
  }
}
</style>
